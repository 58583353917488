import React, {useState} from "react";
import '../styles/katalog.css';
import ti from "../images/tovimage.jpg";
import dr from "../images/Drip.jpg";
import tb from "../images/tovbuy.jpg";
import tl from "../images/tovlike.jpg";

interface Props {
    name: string;
    opis: string;
    price: number;
    price250: number;
    id: number;
}

function KartTovarOpt(props: Props) {

    const [PriceGr, setPriceGr] = useState(() => {
        const initialState = function () {
            if (props.price250 === props.price) {
                return <div className='tovpriceD'>
                    <div className='tovpricegr'>1шт</div>
                    {props.price}₽
                </div>
            } else {
                return <div className='tovpriceRow'>
                    <div className='tovpriceD'>
                        <div className='tovpricegr'>250г</div>
                        {props.price250}₽
                    </div>
                    <div className='tovpriceD'>
                        <div className='tovpricegr'>1000г</div>
                        {props.price}₽
                    </div>
                </div>
            }
        }
        return initialState()
    })

    const [TI, setTI] = useState(() => {
        const initialState = function () {
            if(props.price250 === props.price){
                return dr;
            }
            else {
                return ti;
            }
        }
        return initialState()
    })

    return(
        <div className='karttov'>
            <div className="tovhead">
                <div className="tovname">
                    {props.name}
                </div>
                <div>
                    натуральный
                </div>
            </div>
            <div className="bottomCont">
                <div className="tovcont">
                    <img src={TI} alt="ti"/>
                    <div className="tovopis">
                        {props.opis}
                    </div>
                </div>
                <br/>
                <div className='optpricerow'>
                    {PriceGr}
                </div>
            </div>
        </div>
    );
}

export default KartTovarOpt;