import React from "react";

function City(){
    return(
        <div className="CityText">
            <div>
                Свыше 3800
            </div>
            <div>
                пунктов выдачи
            </div>
        </div>
    );
}

export default City;