import React from "react";
import Hader from "../Hader";
import Futer from "../Futer";
import '../../styles/Reset.css';
import axios from "axios";
import ServHost from "../../serverHost.json";
import {SubmitHandler, useForm} from "react-hook-form";

interface MyForm {
    pass: string,
    passp: string,
}

function ResetURL() {

    const sendDataToServer = async (data:{ pass: string, passp: string, url:string }) => {
        try {
            const res = await axios.post(ServHost.host + '/ResetPass', data);
            console.log(res.data)
            if(res.data.status === "ok"){
                window.location.replace("/login");
            }
        } catch (error) {
            console.error(error);
        }
    };

    const submit: SubmitHandler<MyForm> = data => {
        let URL = window.location.href
        let url = "0";
        if(URL.split("?").length > 1){
            url = URL.split("?")[1]
        }
        sendDataToServer({ pass: data.pass, passp: data.passp, url:url });
    }

    const {
        register,
        watch,
        formState: { errors},
        handleSubmit
    } = useForm<MyForm>({mode: "onBlur"});

    const confPass = (value:string) => {
        const sameValue = watch('pass');
        return value === sameValue || 'Пароли не совпадают';
    };

    return(
        <div>
            <Hader/>
            <div className="contApp">
                <form onSubmit={handleSubmit(submit)}>
                    <div className="noabsformVhod">
                        <label className="lableVhlog">ВОССТАНОВЛЕНИЕ ПАРОЛЯ</label>

                        <input type="password" placeholder="* Новый Пароль"
                               className="inpVhlog" {...register('pass', {
                            required: true,
                            minLength: {
                                value: 5,
                                message: 'Минимальное количество символов 5'
                            },
                            maxLength: {
                                value: 16,
                                message: 'Максимальное количество символов 16'
                            }
                        })}/>
                        {errors?.pass && <div className="Error">{errors?.pass?.message || 'Поле обязательно к заполнению!'}</div>}
                        <input type="password" placeholder="* Подтвердите Пароль"
                               className="inpVhlog" {...register('passp', {
                            required: true,
                            validate: confPass
                        })}/>
                        {errors?.passp && <div className="Error">{errors?.passp?.message || 'Поле обязательно к заполнению!'}</div>}
                        <div>
                            <button className="ButtonRes">ПРОДОЛЖИТЬ</button>
                        </div>
                    </div>
                </form>
            </div>
            <Futer/>
        </div>
    );
}

export default ResetURL;