import React, {useEffect, useState} from "react";
import Hader from "../Hader";
import Futer from "../Futer";
import {Link} from "react-router-dom";
import "../../styles/basket.css"
import IMask from "imask";
import Katalog from "../Katalog";
import axios from "axios";
import ServHost from "../../serverHost.json"
import {INTEGER} from "sequelize";


function Basket(){

    const sendDataToServerUpdateBasket = async (data:{ mail: string, pass: string , value: string}) => {
        try {
            const res = await axios.post(ServHost.host + '/UpdateBasket', data);
            if(res.data.res !== ""){
                console.log(res.data.res)
            }
        } catch (error) {
            console.error(error);
        }
    };

    const sendDataToServerProsent = async (data:{ Promo:string }) => {
        try {
            const res = await axios.post(ServHost.host + '/GetProsent', data);
            if(res.data.res !== ""){
                window.localStorage.setItem('Prosent',String(1-(Number(res.data.res)*0.01)));
            }
        } catch (error) {
            console.error(error);
        }
    };

    const sendDataToServerPayment = async (data:{ adress:string, mail: string, tel: string, name: string, basket:string, baskCount:string, delprice:string, Promo:string, Mail:string, Pass:string }) => {
        try {
            console.log(data)
            const res = await axios.post(ServHost.host + '/GetPaymentURL',data);
            if(res.data !== ""){
                // @ts-ignore
                document.getElementById('errMail').style.display = "none";
                window.open(res.data, '_self');
            }
        } catch (error) {
            console.error(error);
            // @ts-ignore
            document.getElementById('errMail').style.display = "block";
        }
    };

    const sendDataToServerCheckHistoryPromo = async (data:{ mail: string, pass: string }) => {
        try {
            const res = await axios.post(ServHost.host + '/GetUserHistoryPromo',data);
            // @ts-ignore
            let PromoCode = document.getElementById('PromoCode').value;
            let arr = res.data.res.split(' ')
            let result = false;
            for (let i = 0; i < arr.length; i++) {
                if (arr[i] === PromoCode) {
                    result = true;
                }
            }
            if(result){
                // @ts-ignore
                document.getElementById('errUsingCode').style.display = "block";
                // @ts-ignore
                document.getElementById('OKCode').style.display = "none";
                window.localStorage.setItem('Prosent',String(1));
                localStorage.removeItem('UsingPromo');
            }
            else {
                // @ts-ignore
                document.getElementById('errUsingCode').style.display = "none";
                // @ts-ignore
                document.getElementById('OKCode').style.display = "block";
                window.localStorage.setItem('UsingPromo',PromoCode.toUpperCase());
                sendDataToServerProsent({Promo:PromoCode.toUpperCase()});
            }
        } catch (error) {
            console.error(error);
            // @ts-ignore
            document.getElementById('errUsingCode').style.display = "none";
        }
    };

    const sendDataToServerCheckPromo = async (data:{ Promo:string }) => {
        try {
            const res = await axios.post(ServHost.host + '/checkPromo',data);
            if(res.data.res === 0){
                // @ts-ignore
                document.getElementById('errCode').style.display = "block";
                // @ts-ignore
                document.getElementById('OKCode').style.display = "none";
                window.localStorage.setItem('Prosent',String(1));
                localStorage.removeItem('UsingPromo');
            }
            else {
                // @ts-ignore
                document.getElementById('errCode').style.display = "none";
                let login = window.localStorage.getItem('Login');
                if (login){
                    sendDataToServerCheckHistoryPromo({mail:login.split(" ")[0], pass:login.split(" ")[1]})
                }
            }
        } catch (error) {
            console.error(error);
            // @ts-ignore
            document.getElementById('errCode').style.display = "none";
        }
    };

    const UpdateDBBasket = () => {
        let a = window.localStorage.getItem('Login')
        if(a){
            let b = window.localStorage.getItem('basket')
            if(b) {
                sendDataToServerUpdateBasket({ mail: a.split(" ")[0], pass: a.split(" ")[1], value: b })
            }
            if(!b || b === ""){
                sendDataToServerUpdateBasket({ mail: a.split(" ")[0], pass: a.split(" ")[1], value: "" })
            }
        }
    }

    const [PustoLogo] = useState(() => {
        const initialState = function () {
            if(!window.localStorage.getItem("basket")) {
                return <div className="LikedKat">ВАША КОРЗИНА ПУСТА <br/><br/></div>
            }
            else {
                return <div></div>;
            }
        }
        return initialState()
    })

    useEffect(() => {

        let a = "";
        let b = "";
        let c = "";

        window.localStorage.setItem('Prosent',String(1));
        localStorage.removeItem('UsingPromo');

        const interval = setInterval(() => {
            if(a !== window.localStorage.getItem('backCount') || b !== window.localStorage.getItem('delprise') || c !== window.localStorage.getItem('Prosent')){
                // @ts-ignore
                a = window.localStorage.getItem('backCount')
                // @ts-ignore
                b = window.localStorage.getItem('delprise')
                // @ts-ignore
                c = window.localStorage.getItem('Prosent')
                if(!window.localStorage.getItem("basket")) {
                    setContent(
                        <div className="centerText">
                            Чтобы увидеть сохраненные в корзине товары,
                            <Link to={"/login"} className='linkHeader'>авторизуйтесь.</Link><br/><br/><br/>
                        </div>
                    )
                }
                else {
                    // @ts-ignore
                    setContent(LoadContentIFBask(window.localStorage.getItem('backCount'),window.localStorage.getItem('delprise'),window.localStorage.getItem("Prosent")))
                }
            }
        }, 100);

        return () => clearInterval(interval);
    }, []);

    const LoadContentIFBask = (BC:string,DP:string,PR:string) => {
        return <div className="basketContent">
            <div className="OformlenieCont">
                <div className="Oformlenie">
                    <div className="paddingCont">
                        <br/>
                        <div className="baskZagol whiteText">ОФОРМЛЕНИЕ</div>
                        <br/>
                        <br/>
                        <div className="baskText grayText">Покупатель</div>
                        <br/>
                        <div className="inpGor">
                            <div className="TelBask">
                                <input type="text" id='name' placeholder="* Имя и Фамилия"
                                       className="inpBasklog whiteText"/>
                                <input type="text" id='mail' placeholder="* E-mail" className="inpBasklog whiteText"/>
                            </div>
                            <div className="TelBask">
                                <input type="text" placeholder="* Телефон" id="tel" className="inpBasklog whiteText"/>
                            </div>
                        </div>
                    </div>
                </div>
                <br/>
                <br/>
                <br/>
                <div className="AddrDost">
                    <button className='ButtonPVZ' onClick={() => {
                        // @ts-ignore
                        document.getElementById('cdek-back').style.display = "flex";
                    }}>ВЫБРАТЬ ПУНКТ ВЫДАЧИ
                    </button>
                    <div id='adress'>Адрес: Не выбран</div>
                    <div id='time'></div>
                    <div id='price'></div>
                    <div className="cdek-back" id='cdek-back' style={{display: "none"}}>
                        <button className="cdek-close" onClick={() => {
                            // @ts-ignore
                            document.getElementById('cdek-back').style.display = "none";
                        }}>ЗАКРЫТЬ
                        </button>
                        <div id="forpvz" className="cdek"></div>
                    </div>
                </div>
                <br/>
                <br/>
                <div className='line1'></div>
                <br/>
                <div className='PromoCont'>
                    <input type="text" id="PromoCode" placeholder="ПРОМОКОД" className="inpBasklog2"/>
                    <br/>
                    <button className="ButtonVh" onClick={() => {
                        let Login = window.localStorage.getItem("Login")
                        // @ts-ignore
                        let PromoCode = document.getElementById('PromoCode').value
                        console.log(PromoCode);
                        // @ts-ignore
                        if (!Login) {
                            // @ts-ignore
                            document.getElementById('errLogin').style.display = "block";
                        } else {
                            // @ts-ignore
                            document.getElementById('errLogin').style.display = "none";
                            // @ts-ignore
                            sendDataToServerCheckPromo({Promo: PromoCode})
                        }
                    }}>ПРИМЕНИТЬ
                    </button>
                </div>
                <br/>
                <div className="Error textCenter" id='errLogin' style={{display: 'none'}}>Для применения промокода войдите или зарегистрируйтесь</div>
                <div className="Error textCenter" id='errCode' style={{display: 'none'}}>Такого промокода не существует</div>
                <div className="Error textCenter" id='errUsingCode' style={{display: 'none'}}>Промокод уже был вами активирован</div>
                <div className="textCenter" id='OKCode' style={{display: 'none'}}>Промокод успешно применён</div>
                <br/>
                <div className="Yookassa">
                    <div className="VsegoText">
                        всего {Number(BC) * Number(PR) + Number(DP)}₽
                    </div>
                    <button className="ButtonVh" onClick={() => {
                        let back = window.localStorage.getItem("basket")
                        let Count = window.localStorage.getItem("backCount")
                        let DelP = window.localStorage.getItem("delprise")
                        let PromoC = "";
                        let Mail = "";
                        let Pass = "";
                        if(window.localStorage.getItem("UsingPromo")) {
                            // @ts-ignore
                            PromoC = window.localStorage.getItem("UsingPromo");
                        }
                        if(window.localStorage.getItem("Login")) {
                            // @ts-ignore
                            Mail = window.localStorage.getItem("Login").split(" ")[0];
                        }
                        if(window.localStorage.getItem("Login")) {
                            // @ts-ignore
                            Pass = window.localStorage.getItem("Login").split(" ")[1];
                        }
                        // @ts-ignore
                        if (document.getElementById('adress').textContent === 'Адрес: Не выбран' || document.getElementById('tel').value.includes("_") || document.getElementById('name').value === "" || document.getElementById('mail').value === "") {
                            // @ts-ignore
                            document.getElementById('err').style.display = "block";
                        } else {
                            // @ts-ignore
                            document.getElementById('err').style.display = "none";
                            if (back && Count && DelP) {
                                sendDataToServerPayment({
                                    // @ts-ignore
                                    adress: document.getElementById('adress').textContent,
                                    // @ts-ignore
                                    mail: document.getElementById('mail').value,
                                    // @ts-ignore
                                    tel: document.getElementById('tel').value,
                                    // @ts-ignore
                                    name: document.getElementById('name').value,
                                    basket: back,
                                    baskCount: Count,
                                    delprice: DelP,
                                    Promo:PromoC,
                                    Mail:Mail,
                                    Pass:Pass
                                })
                            }
                        }
                    }}>ОПЛАТИТЬ
                    </button>
                </div>
                <div className="Error textCenter" id='err' style={{display: 'none'}}>Для оформления заказа укажите ФИО,
                    ТЕЛЕФОН и выберите ПУНКТ ВЫДАЧИ
                </div>
                <div className="Error textCenter" id='errMail' style={{display: 'none'}}>Не корректная почта</div>
            </div>
            <div className="Bask">
                <div className="paddingCont">
                    <br/>
                    <div className="baskZagol">КОРЗИНА</div>
                    <br/>
                    <br/>
                    <Katalog type={'korzina'} katcount={0}/>
                    <br/>
                    <br/>
                    <div className="KorzVsego">
                        <div className="KorzVsegoText">
                            Сумма заказа {Number(BC) * Number(PR)}₽
                        </div>
                        <button className="KorzVsegobutton" onClick={() => {
                            window.localStorage.setItem("basket","")
                            window.localStorage.setItem("backCount","0")
                            UpdateDBBasket()
                            window.location.reload()
                        }}>Удалить все товары</button>
                    </div>
                </div>
            </div>
        </div>;
    }

    const [Content, setContent] = useState(() => {
        const initialState = function () {
            if(!window.localStorage.getItem("basket")) {
                return <div>
                    Чтобы увидеть сохраненные в корзине товары,
                    <Link to={"/login"} className='linkHeader'>авторизуйтесь.</Link><br/><br/><br/>
                </div>
            }
            else {
                // @ts-ignore
                return LoadContentIFBask(window.localStorage.getItem('backCount'), window.localStorage.getItem('delprise'),window.localStorage.getItem("Prosent"));
            }
        }
        return initialState()
    })

    const [PustoBtn] = useState(() => {
        const initialState = function () {
            if(!window.localStorage.getItem("basket")) {
                return <div><Link to={"/buy"} className='linkHeader'>
                    <button className="ButtonPusto">Перейти в каталог</button>
                </Link></div>
            }
            else {
                return <div></div>;
            }
        }
        return initialState()
    })

    useEffect(() => {
        if (document.getElementById('tel')){
            const element = document.getElementById('tel');
            const maskOptions = {
                mask: '+7(000)000-00-00',
                lazy: false
            }
            // @ts-ignore
            const mask = new IMask(element, maskOptions);
        }
    });

    useEffect(() => {
        window.localStorage.setItem("delprise",'0');
        // @ts-ignore
        var widjet = new ISDEKWidjet({
            defaultCity: 'Москва',
            cityFrom: 'Волгореченск',
            hidedelt: true,
            hidecash: true,
            hidedress: true,
            goods: [
                {
                    length: 12,
                    width: 8,
                    height: 4,
                    weight: 1
                }
            ],
            link: 'forpvz',
        });
        widjet.binders.add(choosePVZ, 'onChoose');

        // @ts-ignore
        function choosePVZ(wat) {
            // @ts-ignore
            document.getElementById('cdek-back').style.display = "none";
            // @ts-ignore
            document.getElementById('adress').textContent ='Адрес:  г.' + wat.cityName + ", " + wat.PVZ.Address;
            // @ts-ignore
            document.getElementById('time').textContent = 'Внемя доставки:  ' + wat.term + ' дн.';
            // @ts-ignore
            document.getElementById('price').textContent = 'Стоимость доставки:  ' + wat.price + '₽';
            window.localStorage.setItem("delprise",wat.price);
        }
    }, []);

    return (
        <div>
            <Hader/>
            <br/>
            <br/>
            <div className="contApp">
                {PustoLogo}
                {Content}
                {PustoBtn}
            </div>
            <Futer/>
        </div>
    );
}

export default Basket;